import * as React from 'react';
import queryString from 'query-string';
import useSWR from 'swr';
import { request } from 'graphql-request';

// Require the presentation component here, this is shared between the SSR template
// and the runtime preview template
import Page from '../../templates/presentation/page';

// Get the variables for the query
const variables =
  typeof window !== 'undefined'
    ? {
        id: queryString.parse(window.location.search).id,
      }
    : {};

const fetcher = (query) => request(process.env.GATSBY_WP_API, query, variables);

const PagePreview = () => {
  const { data, error } = useSWR(
    `
    query MyQuery($id: ID!) {
      page: page(id: $id, idType: DATABASE_ID) {
        title
        slug
        featuredImage {
          node {
            sourceUrl
          }
        }
        acfGenericPage {
          overviewTitle
          overviewBody
          overviewForeword
        }
        parent {
          node {
            ... on Page {
              id
              title
              slug
              children {
                nodes {
                  ... on Page {
                    id
                    title
                    slug
                  }
                }
              }
            }
          }
        }
        children {
          nodes {
            ... on Page {
              id
              title
              slug
            }
          }
        }
      }
      staff: staffMembers {
        edges {
          node {
            id
            title
            slug
            staffAcf {
              jobCategory
              jobTitle
              order
              profileImage {
                sourceUrl
              }
            }
            staffCard {
              cardSummaryText
              hideProfileLink
            }
          }
        }
      }
    }
  `,
    fetcher
  );

  if (error) return <div>failed to load</div>;
  if (!data) return <div>loading...</div>;

  return (
    // <>
    //   <p>Preview example</p>
    //   <pre>{JSON.stringify(data, null, 2)}</pre>
    // </>
    <Page data={{ ...data.page, staff: data.staff }} slug={data.slug} />
  );
};

export default PagePreview;
